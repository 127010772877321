.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .floating-button img {
    width: 30px;
    height: 30px;
  }
  
  .floating-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Support */
  @media (max-width: 768px) {
    .floating-button {
      width: 50px;
      height: 50px;
      bottom: 15px;
      right: 15px;
    }
  
    .floating-button img {
      width: 25px;
      height: 25px;
    }
  }
  